import { createAsyncThunk } from '@reduxjs/toolkit';

import { WithTypesType } from '../types/WithTypesType';
import { SuperAdministrationService } from '../../services/superAdministratorService';

export const getBussinesses = createAsyncThunk.withTypes<WithTypesType>()(
  'employerSuperAdministrator/getBussinesses',
  async (_, thunkAPI) => {
    try {
      const { payload } = await SuperAdministrationService.getBussinesses();
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const setCurrentBussiness = createAsyncThunk(
  'employerSuperAdministrator/setCurrentBussiness',
  async (_, thunkAPI) => {
    try {
      const { payload } = await SuperAdministrationService.getCurrentBussiness();
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const updateSuperAdminCurrentBussinessAction = createAsyncThunk.withTypes<WithTypesType>()(
  'employerSuperAdministrator/updateSuperAdminCurrentBussinessAction',
  async (params: { bussinessId: number }, thunkAPI) => {
    try {
      const { bussinessId } = params;
      const { payload } = await SuperAdministrationService.updateSuperAdminCurrentBussiness(bussinessId);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const addSimulationTypesToBussiness = createAsyncThunk.withTypes<WithTypesType>()(
  'employerAdministration/addSimulationTypesToBussiness',
  async (
    params: {
      simulationTypeIds: { id: number; name: string; customerUUID: string }[];
    },
    thunkAPI,
  ) => {
    const { simulationTypeIds } = params;
    try {
      const { payload } = await SuperAdministrationService.addSimulationToBussiness(simulationTypeIds);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const updateSimulationLanguagesToBusiness = createAsyncThunk.withTypes<WithTypesType>()(
  'employerAdministration/updateSimulationLanguagesToBusiness',
  async (
    params: {
      uniqueLinks: string[];
    },
    thunkAPI,
  ) => {
    const { uniqueLinks } = params;
    try {
      const { payload } = await SuperAdministrationService.updateSimulationLanguagesToBusiness(uniqueLinks);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);

export const getNotAssignedSimulationTypes = createAsyncThunk.withTypes<WithTypesType>()(
  'employerAdministration/getNotAssignedSimulationTypes',
  async (
    params: {
      customerUUID: string;
    },
    thunkAPI,
  ) => {
    try {
      const { payload } = await SuperAdministrationService.getNotAssignedSimulationTypes(params.customerUUID);
      return payload;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.name);
    }
  },
);
