import { useNavigate } from 'react-router-dom';

import { RegisterForm } from '../../components/RegisterForm/RegisterForm';

import { Heading } from '../../components/Reusable-styled-elements/Heading';

import { RegisterFormContainer, SparklesIcon } from './style';
import { PageContainer, PageWrapper } from '../../components/Reusable-styled-elements/PageContainer';
import { Welcome } from '../Welcome/Welcome';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { ROUTE_LOGIN } from '../../constants/routes';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store/store';
import { IAuthSlice, resetError } from '../../store/slices/authSlice';
import { QuestionnairePhase } from './QuestionnairePhase/QuestionnairePhase';
import { Subheader } from '../../components/Reusable-styled-elements/Subheader';
import { PageIndicator } from '../../components/PageIndicator/PageIndicator';
import { LogoContainerWrapper } from '../../components/LogoContainer/LogoContainerWrapper';
import { textTemplates } from '../../utils/types';
import { MobileRegisterWelcome } from '../../components/MobileRegisterWelcome/MobileRegisterWelcome';
import { Language } from '../../utils/languages/general';
import { getRegistrationTexts } from '../../services/languages.service';
export interface IUser {
  email: string;
  password: string;
}

const registerationPhases = {
  cognitoPhase: 'cognitoPhase',
  questionnairePhase: 'questionnairePhase',
} as const;

export interface RegisterProps {
  customEmailRegex?: RegExp;
  platformName: string;
  isBusinessUser?: boolean;
  invitedEmail?: string;
  registrationLanguage?: Language;
  ttid?: string;
}
type OneOfRegisterationPhases = (typeof registerationPhases)[keyof typeof registerationPhases];

export const Register: React.FC<RegisterProps> = ({
  customEmailRegex,
  platformName,
  isBusinessUser,
  invitedEmail,
  registrationLanguage,
  ttid,
}) => {
  const { authenticated, platformUser, cognitoUser } = useSelector<RootState, IAuthSlice>(
    (state) => state.authSliceReducer,
  );

  const [phase, setPhase] = useState<OneOfRegisterationPhases>(registerationPhases.cognitoPhase);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [wasWelcomed, setWasWelcomed] = useState<boolean>(false);
  const navigateToLogin = () => {
    dispatch(resetError());
    navigate(ROUTE_LOGIN, { state: { hideWelcome: true } });
  };

  function removeQueryStringIfNeeded(url: string) {
    const index = url.indexOf('?');
    if (index !== -1) {
      return url.slice(0, index);
    }
    return url; // Return the original string if there is no '?'
  }

  const { isMobile } = useWindowDimensions({ widthConsideredAsMobile: 1024 });
  let uniqueLink = '';

  useEffect(() => {
    if (authenticated && !platformUser) {
      setPhase(registerationPhases.questionnairePhase);
    } else if (authenticated && platformUser) {
      if (uniqueLink) {
        navigate(ttid ? `/${uniqueLink}?ttid=${ttid}` : `/${uniqueLink}`);
      } else {
        navigateToLogin();
      }
    }
  }, [authenticated, platformUser]);

  if (localStorage.getItem('desiredUrl')) {
    uniqueLink = removeQueryStringIfNeeded(localStorage.getItem('desiredUrl')?.replace('/', '') ?? '');
  }
  if (cognitoUser) {
    if (cognitoUser.attributes?.['custom:unique_link'] && cognitoUser.attributes?.['custom:unique_link'].length === 8)
      uniqueLink = cognitoUser.attributes?.['custom:unique_link'];
  }
  if (phase === registerationPhases.questionnairePhase)
    return (
      <QuestionnairePhase
        platformName={platformName}
        isBusinessUser={isBusinessUser}
        uniqueLink={uniqueLink}
        registrationLanguage={registrationLanguage}
        ttid={ttid}
      />
    );
  const texts = getRegistrationTexts(registrationLanguage);
  const registerPage = (
    <PageWrapper>
      {!isMobile && (
        <Welcome
          textTemplate={textTemplates.SIGNUP}
          isBusinessUser={isBusinessUser}
          registrationLanguage={registrationLanguage}
        />
      )}
      <PageContainer>
        {isMobile && <LogoContainerWrapper clickHandler={navigateToLogin} style={{ alignSelf: 'start' }} />}
        <RegisterFormContainer>
          <SparklesIcon />
          <Heading>{texts.setupYourAccount}</Heading>
          <Subheader>{texts.provideEmail}</Subheader>
          <RegisterForm
            customEmailRegex={customEmailRegex}
            platformName={platformName}
            navigateToLogin={navigateToLogin}
            invitedEmail={invitedEmail}
            registrationLanguage={registrationLanguage}
          />
        </RegisterFormContainer>
        <PageIndicator totalPages={2} currentPage={1}></PageIndicator>
      </PageContainer>
    </PageWrapper>
  );

  const mobileRegister =
    !wasWelcomed && !isBusinessUser ? (
      <MobileRegisterWelcome onContinue={setWasWelcomed} navigateToLogin={navigateToLogin} />
    ) : (
      registerPage
    );

  return isMobile ? mobileRegister : registerPage;
};
