import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EmployerSuperAdministration, BussinessesForSuperAdmin } from '../types/EmployerSuperAdministrationInterface';
import {
  addSimulationTypesToBussiness,
  getBussinesses,
  getNotAssignedSimulationTypes,
  setCurrentBussiness,
  updateSuperAdminCurrentBussinessAction,
  updateSimulationLanguagesToBusiness,
} from '../actions/employerSuperAdministrator.action';
import { NotAssignedSimulationTypes } from '../types/EmployerAdministrationInterface';

const initialState: EmployerSuperAdministration = {
  bussinesses: [],
  currentBussiness: null,
  loading: false,
  loadingCurrentCustomer: false,
  loadingUpdateCustomer: false,
  loadingAddingSimToBussiness: false,
  notAssignedSimulationTypes: [],
  loadingSimulationLanguages: false,
};

const employersSuperAdministrationSlice = createSlice({
  name: 'employerSuperAdministrator',
  initialState,
  reducers: {
    setBussinessesList(state, { payload }: PayloadAction<{ bussinesses: BussinessesForSuperAdmin[] }>) {
      state.bussinesses = payload.bussinesses;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBussinesses.fulfilled, (state, { payload }: PayloadAction<BussinessesForSuperAdmin[]>) => {
        state.bussinesses = payload;
        state.loading = false;
      })
      .addCase(getBussinesses.rejected, (state) => {
        state.bussinesses = [];
        state.loading = true;
      })
      .addCase(getBussinesses.pending, (state) => {
        state.loading = true;
      })
      .addCase(setCurrentBussiness.pending, (state) => {
        state.loadingCurrentCustomer = true;
      })
      .addCase(setCurrentBussiness.rejected, (state) => {
        state.loadingCurrentCustomer = false;
      })
      .addCase(setCurrentBussiness.fulfilled, (state, { payload }: PayloadAction<BussinessesForSuperAdmin>) => {
        state.loadingCurrentCustomer = false;
        state.currentBussiness = payload;
      })
      .addCase(updateSuperAdminCurrentBussinessAction.pending, (state) => {
        state.loadingUpdateCustomer = true;
      })
      .addCase(updateSuperAdminCurrentBussinessAction.rejected, (state) => {
        state.loadingUpdateCustomer = false;
      })
      .addCase(updateSuperAdminCurrentBussinessAction.fulfilled, (state) => {
        state.currentBussiness = null;
        state.loadingUpdateCustomer = false;
      })
      .addCase(addSimulationTypesToBussiness.fulfilled, (state) => {
        state.loadingAddingSimToBussiness = false;
      })
      .addCase(addSimulationTypesToBussiness.rejected, (state) => {
        state.loadingAddingSimToBussiness = false;
      })
      .addCase(addSimulationTypesToBussiness.pending, (state) => {
        state.loadingAddingSimToBussiness = true;
      })
      .addCase(updateSimulationLanguagesToBusiness.pending, (state) => {
        state.loadingSimulationLanguages = true;
      })
      .addCase(updateSimulationLanguagesToBusiness.rejected, (state) => {
        state.loadingSimulationLanguages = false;
      })
      .addCase(updateSimulationLanguagesToBusiness.fulfilled, (state) => {
        state.loadingSimulationLanguages = false;
      })
      .addCase(
        getNotAssignedSimulationTypes.fulfilled,
        (state, { payload }: PayloadAction<NotAssignedSimulationTypes[]>) => {
          state.notAssignedSimulationTypes = payload;
          state.loading = false;
        },
      )
      .addCase(getNotAssignedSimulationTypes.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getNotAssignedSimulationTypes.pending, (state) => {
        state.loading = true;
      });
  },
});

export const employersSuperAdministrationSliceReducer = employersSuperAdministrationSlice.reducer;
