import { API } from '@skillset/onelogin';

export const getBussinesses = async () => {
  const bussinesses = await API.get('api', `/employers/superadmin/getAllBussinesses`, {});
  return bussinesses;
};

export const updateSuperAdminCurrentBussiness = async (bussinessId: number) => {
  return API.post('api', `/employers/superadmin/updateSuperAdminCurrentBussiness`, {
    body: { businessId: bussinessId },
  });
};

export const getCurrentBussiness = async () => {
  const bussinesses = await API.get('api', `/employers/superadmin/getCurrentBussiness`, {});
  return bussinesses;
};

export const addSimulationToBussiness = async (simulationTypeIds: { id: number; name: string }[]) => {
  return API.post('api', `/employers/superadmin/addSimulationToBussiness`, { body: { simulationTypeIds } });
};

export const updateSimulationLanguagesToBusiness = async (uniqueLinks: string[]) => {
  return API.post('api', `/employers/superadmin/updateSimulationLanguagesToBusiness`, { body: { uniqueLinks } });
};

export const getNotAssignedSimulationTypes = async (customerUUID: string) => {
  const uniqueLinks = await API.get('api', `/employers/superadmin/getNotAssignedSimulationTypes/${customerUUID}`, {});
  return uniqueLinks;
};

export const SuperAdministrationService = {
  getBussinesses,
  updateSuperAdminCurrentBussiness,
  getCurrentBussiness,
  addSimulationToBussiness,
  getNotAssignedSimulationTypes,
  updateSimulationLanguagesToBusiness,
};
