export default {
  stage: 'prod',
  s3: {
    REGION: 'eu-central-1',
    BUCKET: 'eu-central-1-skillset-storage-main-site-prod',
  },
  apiGateway: {
    REGION: 'eu-central-1',
    URL: 'https://503nhr8r35.execute-api.eu-central-1.amazonaws.com/main-site-prod',
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_rTmSSqJA4',
    APP_CLIENT_ID: '71qku6cm1rfj22pnsc10ld8vpu',
    IDENTITY_POOL_ID: 'eu-central-1:ee8678b4-6ec8-45c4-93b7-74559834c31f',
    GOOGLE_REDIRECT_SIGN_IN: location.origin + '/social-login',
    GOOGLE_REDIRECT_SIGN_OUT: location.origin + '/login',
    GOOGLE_COGNITO_DOMAIN: 'tatio.auth.us-east-1.amazoncognito.com',
  },
  social: {
    GOOGLE_ANALYTICS: 'UA-182396580-1',
    TAG_MANAGER_ARGS: 'GTM-TP2N8K2',
  },
  webCameraUse: {
    disable: false,
  },
  urls: {
    simulations_url: 'https://simulations.tatio.io/',
    api_documentation: 'developers.tatio.io',
  },
};
