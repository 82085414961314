import { Route, Routes } from 'react-router-dom';

export { API, Hub } from 'aws-amplify';
export { Login } from './pages/Login/Login';
export { AnonymousLogin } from './pages/AnonymousLogin/AnonymousLogin';

export { LoginWithGoogle } from './pages/LoginWithGoogle/LoginWithGoogle';
export { userService } from './services/user.service';
export type { Language } from './utils/languages/general';
export { getNoSimulatinsTexts, getHeaderTexts, getLoginTexts } from './services/languages.service';
export { DefaultLanguage } from './utils/languages/general';
export { loginTexts } from './utils/languages/LoginTexts';
export { headerTexts } from './utils/languages/HeaderTexts';
export { noSimulationTexts } from './utils/languages/NoSimulationTexts';
export { TermsOfUse } from './pages/TermsOfUse/TermsOfUse';
export { testLogin } from './tests/login.spec';
import { ForgotPassword } from './pages/ForgotPassword/ForgotPassword';
import { ResendPassword } from './pages/ResendPassword/ResendPassword';
import { ResetPassword } from './pages/ResetPassword/ResetPassword';
import { ResetSuccess } from './pages/ResetSuccess/ResetSuccess';

import { ConfirmEmailMessage } from './pages/ConfirmEmailMessage/ConfirmEmailMessage';

import { ConfirmEmail } from './pages/ConfirmEmail/ConfirmEmail';

import { Register, RegisterProps } from './pages/Register/Register';
import { Language } from './utils/languages/general';

export { PublicRoute } from './components/PublicRoute/PublicRoute';
export { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
export { authSliceReducer } from './store/slices/authSlice';
export { setupAmplify, storageKeys } from './constants';
export type { IAuthSlice } from './store/slices/authSlice';
export * from './store/selectors';
export { getAuthenticatedUser } from './store/actions/auth.action';
export { signOut } from './store/actions/auth.action';
export { platformAnalyticsInit, triggerAnalyticsEvent } from './services/platform.seo';
export { UserMenu } from './components/UserMenu/UserMenu';
export type { IUserMenuOptions } from './components/UserMenu/UserMenu';
export { getBrandColorByString } from './utils/general';
/**
 * must be Wrapped by Suspense
 */
export const SignUp: React.FC<RegisterProps> = ({
  customEmailRegex,
  platformName,
  isBusinessUser,
  invitedEmail,
  registrationLanguage,
  ttid,
}) => {
  const register = (
    <Register
      isBusinessUser={isBusinessUser}
      customEmailRegex={customEmailRegex}
      platformName={platformName}
      invitedEmail={invitedEmail}
      registrationLanguage={registrationLanguage}
      ttid={ttid}
    />
  );
  return (
    <Routes>
      <Route index element={register} />
      <Route
        path="confirm"
        element={
          <ConfirmEmailMessage isBusinessUser={isBusinessUser ?? false} registrationLanguage={registrationLanguage} />
        }
      />
      <Route path="confirm/:username/:code" element={<ConfirmEmail platformName={platformName} />} />
      <Route path=":token" element={register} />
    </Routes>
  );
};

/**
 * must be Wrapped by Suspense
 */
export const RecoverAccount: React.FC<{ registrationLanguage?: Language }> = ({ registrationLanguage }) => {
  return (
    <Routes>
      <Route path="forgot" element={<ForgotPassword registrationLanguage={registrationLanguage} />} />
      <Route path="resend" element={<ResendPassword registrationLanguage={registrationLanguage} />} />
      <Route path="reset/:username/:code" element={<ResetPassword registrationLanguage={registrationLanguage} />} />
      <Route path="reset-success" element={<ResetSuccess registrationLanguage={registrationLanguage} />} />
    </Routes>
  );
};
