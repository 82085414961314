import { API, Auth, Storage } from 'aws-amplify';
// import { Hub } from 'aws-amplify';

/**
 * Configurating AWS Amplify Auth and API classes.
 * Adding Hub event listeners for auth.
 */

export interface Config {
  stage: string;
  s3: {
    REGION: string;
    BUCKET: string;
  };
  apiGateway: {
    REGION: 'us-east-1' | 'eu-central-1' | string;
    URL: string;
  };
  cognito: {
    REGION: 'us-east-1' | 'eu-central-1' | string;
    USER_POOL_ID: string;
    APP_CLIENT_ID: string;
    IDENTITY_POOL_ID: string;
    GOOGLE_REDIRECT_SIGN_IN: string;
    GOOGLE_REDIRECT_SIGN_OUT: string;
    GOOGLE_COGNITO_DOMAIN: string;
  };
  social?: {
    GOOGLE_ANALYTICS: string;
    TAG_MANAGER_ARGS: string;
  };
}

export const setupAmplify = (config: Config) => {
  Auth.configure({
    mandatorySignIn: true,
    signUpVerificationMethod: 'code',
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: config.cognito.GOOGLE_COGNITO_DOMAIN, //'tatio-staging.auth.us-east-1.amazoncognito.com',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: config.cognito.GOOGLE_REDIRECT_SIGN_IN,
      redirectSignOut: config.cognito.GOOGLE_REDIRECT_SIGN_OUT,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  });
  Storage.configure({
    region: config.s3?.REGION,
    bucket: config.s3?.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  });
  API.configure({
    endpoints: [
      {
        name: 'api',
        region: config.apiGateway.REGION,
        endpoint: config.apiGateway.URL,
      },
    ],
  });
};

export const storageKeys = {
  platformUser: 'platformUser',
} as const;
