import { useCountryStateCity } from '../../hooks/useCountryStateCity';
import { isNotEmpty } from '../../utils/validations';
import { ButtonOrangeFilled } from '../Reusable-styled-elements/Button';
import { InputsRowContainer, QuestionnaireButtonContainer, TwoInputFields } from './styles';
import { FormSelectGroup } from '../FormSelectGroup/FormSelectGroup';
import { RootState, useAppDispatch } from '../../store/store';
import { IAuthSlice } from '../../store/slices/authSlice';
import { useSelector } from 'react-redux';
import { saveUserData } from '../../store/actions/auth.action';
import { prepareUserToSave, useCreateInputGroup, useCreatePhoneInputGroup } from './utils';
import { useEffect, useRef, useState } from 'react';
import { LoadingScreen } from '@skillset/ui';
import { userService } from '../../services/user.service';
import { Language } from '../../utils/languages/general';
import { getQuestionnaireTexts } from '../../services/languages.service';

// Orel, please secure this page . Only B2C and B2B should have access to this page and registration .
export const QuestionnaireForm: React.FC<{
  platformName: string;
  uniqueLink: string;
  registrationLanguage?: Language;
  ttid?: string;
}> = ({ platformName, uniqueLink, registrationLanguage, ttid }) => {
  const { cognitoUser } = useSelector<RootState, IAuthSlice>((state) => state.authSliceReducer);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const mountRef = useRef(false);
  const texts = getQuestionnaireTexts(registrationLanguage);
  const createUserWithoutRegistration = async (skip: 'SkipAndCreateUser' | 'SkipCreateAnonymousUser') => {
    try {
      if (!cognitoUser) {
        // If no cognitoUser is available, directly set the desired URL.
        localStorage.setItem('desiredUrl', uniqueLink);
        return;
      }

      // Define default names and location based on the 'skip' type.
      const isDemoUser = skip === 'SkipAndCreateUser';
      const defaultName = isDemoUser ? 'Demo' : 'Anonymous';
      const defaultLocation = isDemoUser ? 'Demo' : 'Anonymous';

      const personalInfo = {
        firstName: defaultName,
        lastName: defaultName,
        phoneNumber: '+18015555555',
        dateOfBirth: '01/01/2023',
        country: defaultLocation,
        state: defaultLocation,
        city: defaultLocation,
        zipCode: defaultLocation,
      };

      // Prepare and save user data.
      const userToSave = await prepareUserToSave(cognitoUser, platformName, personalInfo);
      const results = await dispatch(saveUserData({ userToSave, platformName, uniqueLink, ttid }));

      // Handle the result of the user data saving operation.
      if (results.meta.requestStatus === 'fulfilled') {
        localStorage.setItem('desiredUrl', uniqueLink);
      } else {
        console.error('Failed to save user data', results);
      }
    } catch (error) {
      // Log the error with more context.
      console.error('Error in creating user without registration:', error);
    }
  };

  const shouldSkip = async () => {
    try {
      const canSkip = await userService.shouldSkipQuestioneer(uniqueLink ?? '');
      if (canSkip.skip === 'DontSkip') {
        setLoading(false);
      }
      if (mountRef.current && canSkip.skip != 'DontSkip') createUserWithoutRegistration(canSkip.skip);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    mountRef.current = true;
    shouldSkip();
    return () => {
      mountRef.current = false;
    };
  }, []);

  const {
    value: firstName,
    formInputGroup: firstNameInputElement,
    valueIsValid: firstNameIsValid,
    onBlur: onFirstNameBlur,
  } = useCreateInputGroup(texts.firstName, true, isNotEmpty, registrationLanguage);

  const {
    value: lastName,
    formInputGroup: lastNameInputElement,
    valueIsValid: lastNameIsValid,
    onBlur: onLastNameBlur,
  } = useCreateInputGroup(texts.lastName, true, isNotEmpty, registrationLanguage);

  const {
    value: dateOfBirth,
    formInputGroup: dateOfBirthInputElement,
    valueIsValid: dateOfBirthIsValid,
    onBlur: onDateOfBirthBlur,
  } = useCreateInputGroup(texts.dateOfBirth, false, () => true, registrationLanguage, undefined, 'date');

  const {
    value: phoneNumber,
    formInputGroup: phoneNumberInputElement,
    valueIsValid: phoneNumberIsValid,
    onBlur: onPhoneNumberBlur,
  } = useCreatePhoneInputGroup(texts.phone, true, undefined, undefined, '(555) 555-1234', registrationLanguage);

  const { value: zipCode, formInputGroup: zipCodeInputElement } = useCreateInputGroup(
    texts.zipCode,
    true,
    () => true,
    registrationLanguage,
  );

  const {
    countryObj,
    stateObj,
    cityObj,
    countryObjHasError,
    stateObjHasError,
    cityObjHasError,
    onSelectChanged,
    setTouchedMap,
    touchedMap,
  } = useCountryStateCity({
    countryValidator: (value) => value != '' && value != 'Country',
    stateValidator: (value) => value != '' && value != 'State',
    cityValidator: (value) => value != '' && value != 'City',
  });

  const countryIsValid = countryObj.value != '' && countryObj.value != 'Country';
  const stateIsValid = (stateObj.value != '' && stateObj.value != 'State') || !stateObj.required;
  const cityIsValid = (cityObj.value != '' && cityObj.value != 'City') || !cityObj.required;
  const formIsValid =
    firstNameIsValid &&
    lastNameIsValid &&
    dateOfBirthIsValid &&
    phoneNumberIsValid &&
    countryIsValid &&
    stateIsValid &&
    cityIsValid;

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!cognitoUser) return;

    if (!formIsValid) {
      onFirstNameBlur();
      onLastNameBlur();
      onDateOfBirthBlur();
      onPhoneNumberBlur();
      setTouchedMap({ country: true, state: true, city: true });
      return;
    }
    const personalInfo = {
      firstName,
      lastName,
      phoneNumber,
      dateOfBirth,
      country: countryObj.value,
      state: stateObj.value,
      city: cityObj.value,
      zipCode,
    };
    const userToSave = await prepareUserToSave(cognitoUser, platformName, personalInfo);
    const results = await dispatch(saveUserData({ userToSave, platformName, uniqueLink }));
    if (results.meta.requestStatus === 'fulfilled') {
      localStorage.setItem('desiredUrl', uniqueLink);
    }
  };
  return loading ? (
    <>
      <LoadingScreen text={texts.anonymousLoginMessage} zindex={1} />
    </>
  ) : (
    <>
      <form onSubmit={submitHandler}>
        <InputsRowContainer>
          <TwoInputFields>
            {firstNameInputElement}
            {lastNameInputElement}
          </TwoInputFields>
          <TwoInputFields>
            {dateOfBirthInputElement}
            {phoneNumberInputElement}
          </TwoInputFields>
          <TwoInputFields>
            <FormSelectGroup
              name="country"
              label={texts.country}
              placeholder={texts.country}
              required
              errorMessage={countryObjHasError ? texts.fillFieldError : countryObj.error}
              options={countryObj.options}
              onChange={onSelectChanged}
              touched={touchedMap.country}
              value={countryObj.value}
            />
            <FormSelectGroup
              name="state"
              label={texts.state}
              placeholder={texts.state}
              required={stateObj.required}
              errorMessage={stateObjHasError ? texts.fillFieldError : stateObj.error}
              options={stateObj.options}
              onChange={onSelectChanged}
              touched={touchedMap.state}
              value={stateObj.value}
            />
          </TwoInputFields>
          <TwoInputFields>
            <FormSelectGroup
              name="city"
              label={texts.city}
              placeholder={texts.city}
              errorMessage={cityObjHasError ? texts.fillFieldError : cityObj.error}
              options={cityObj.options}
              onChange={onSelectChanged}
              touched={touchedMap.city}
              required={cityObj.required}
              value={cityObj.value}
            />
            {zipCodeInputElement}
          </TwoInputFields>
          <QuestionnaireButtonContainer>
            <ButtonOrangeFilled type="submit">{texts.continue}</ButtonOrangeFilled>
          </QuestionnaireButtonContainer>
        </InputsRowContainer>
      </form>
    </>
  );
};
