import React, { Suspense, SuspenseProps } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { IAuthSlice } from '../../store/slices/authSlice';
import { NavigateAndResetURL } from '../NavigateAndResetURL/NavigateAndResetURL';
import { useLocation } from 'react-router-dom';

interface IPublicRoute extends Pick<Partial<SuspenseProps>, 'fallback'> {
  Component: React.ComponentType;
  optionalAuthenticatedPath?: `/${string}`;
  platformName?: string;
}

export const PublicRoute: React.FC<IPublicRoute> = ({
  Component: RouteComponent,
  optionalAuthenticatedPath,
  fallback = null,
  platformName,
}) => {
  const { authenticated } = useSelector<RootState, IAuthSlice>((state) => state.authSliceReducer);

  const { search } = useLocation();
  const desiredUrl =
    platformName === 'Simulations' && localStorage.getItem('desiredUrl')
      ? localStorage.getItem('desiredUrl') + search
      : undefined;
  if (!authenticated) {
    return (
      <Suspense fallback={fallback}>
        <RouteComponent />
      </Suspense>
    );
  }
  //The code here is reachable only when the authenticated variable is truthy.
  return <NavigateAndResetURL to={desiredUrl || optionalAuthenticatedPath || '/'} />;
};
