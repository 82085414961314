import { API } from 'aws-amplify';
import { IPlatformUser } from '../utils/types';

const get = (platformName: string, email: string) => {
  return API.get('api', `/users/${platformName}/${email}`, {});
};

const shouldSkipQuestioneer = (uniqueLink: string) => {
  return API.get('api', `/users/validateSkip/${uniqueLink}`, {});
};

const create = (
  userToSave: Omit<IPlatformUser, 'businessId'>,
  platformName: string,
  uniqueLink?: string,
  partnerId?: string,
) => {
  return API.post('api', '/users', {
    body: { ...userToSave, uniqueLink, partnerId },
    platformName,
  });
};

const createAnonymous = (email: string, uniqueLink?: string, partnerId?: string) => {
  return API.post('api', '/users/anonymous/create', {
    body: { email, uniqueLink, partnerId },
  });
};

const getInvitedUserBusiness = () => {
  return API.get('api', '/users/invitedUserBusiness', {});
};

const updateLastVisit = (desiredUrl?: string) => {
  const body: Record<string, string | null> = {
    uniqueLink: desiredUrl
      ? desiredUrl
          .replace('/', '')
          .substring(0, desiredUrl.indexOf('?') > 0 ? desiredUrl.indexOf('?') - 1 : desiredUrl.length - 1)
      : null,
  };
  return API.patch('api', '/users/lastVisit', { body });
};

const getMainDomain = (url: string) => {
  const parsedUrl = new URL(url);
  const hostname = parsedUrl.hostname;
  // Split the hostname into parts
  const parts = hostname.split('.').reverse();
  if (parts.length > 1) {
    // Grab the last two parts of the hostname and reformat as domain
    return parts[1] + '.' + parts[0];
  }
  return hostname;
};

const getDomainFromEmail = (email: string) => {
  const parts = email.split('@');
  return parts[1]; // This assumes the email is well-formed and contains an @ symbol.
};

const shouldLogoutUser = (
  anonymousEmail: string | undefined,
  userEmail: string,
  isAnonymous: string | undefined,
  referrerDomain: string | undefined,
): boolean => {
  return (
    (anonymousEmail !== undefined && userEmail !== anonymousEmail) || // If
    (isAnonymous === '1' && anonymousEmail === undefined && referrerDomain !== undefined) || // user is anonymous
    (anonymousEmail !== undefined && referrerDomain !== undefined && referrerDomain !== getDomainFromEmail(userEmail))
  );
};

interface AuthUserAttributes {
  email?: string;
  'custom:isAnonymous'?: string;
}
interface CognitoUser {
  attributes?: AuthUserAttributes;
  email?: string;
  username?: string;
}
export type { CognitoUser };
export const userService = {
  get,
  create,
  updateLastVisit,
  getInvitedUserBusiness,
  shouldSkipQuestioneer,
  createAnonymous,
  shouldLogoutUser,
  getMainDomain,
  getDomainFromEmail,
};
